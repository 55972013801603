
/**
 * down load item language.
 */
class DlLang {
  
  /**
   * get languages.
   */
  static getLanguages(dlItems, version) {
    const result = [];
    for (let product in dlItems) {
      for (let lang in dlItems[product][version]['modules']) {

        result.push(lang);
      }
    }
    return result;
  }

  constructor() {
  }
}


module.exports = DlLang;

