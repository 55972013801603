

/**
 * handle string mapping 
 */
class StrMap {
  
  /**
   * replace str to the map value if it is the key of mapping
   */
  static replace(mapping, str) {
    let result;
    if (str in mapping) {
      result = mapping[str];
    } else {
      result = str;
    }
    return result;
  }

}


module.exports = StrMap;

