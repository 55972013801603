
/**
 * handle url parameter parsing
 */
class UrlParam {
  /**
   * find parameter value
   */
  static findParam(name) {
    let params = window.location.search;
    let result = undefined;
    if (typeof URLSearchParams !== 'undefined') {
      result = (new URLSearchParams(params)).get(name);
    } else {
      let values = new RegExp('[\?&]' + name + '=([^&#]*)').exec(params); 
      if (values) {
        result = decodeURI(values[1]);
      } else {
        result = null;
      }
    }
    return result;
  }
}

module.exports = UrlParam;
// vi: se ts=2 sw=2 et:
