
/**
 * down load version
 */
class DlVersion {


  /**
   * parse version string
   */
  static parseVersionI(versionStr, tokens) {
    let result = [];

    if (tokens.length > 0) {
      let versionStrs = versionStr.split(tokens[0]);
      if (tokens.length > 1) {
        versionStrs.forEach(verStr => {
          result.concat(DlVersion.parseVersionI(
            verStr, tokens.splice(1))); 
        }); 
      }
    } else {
      result.push(versionStr);
    }
    return result;
  }
  /**
   * parse version string
   */
  static parseVersion(versionStr) {
    let version = versionStr.split(/(?:,|\.)/);
    version = version.map(elem => {
      let res = parseInt(elem);
      if (isNaN(res)) {
        res = elem;
      }
      return res;
    });
    return version;
  }

  /**
   * create version list
   */
  static createVersionList(dlItems) {
    const result = [];
    for (let key in dlItems) {
      for (let ver in dlItems[key]) {
        result.push(ver);
      }
    }
    return result;
  }

  /**
   * get latest version
   */
  static getLatestVersion(dlItems) {
    let versions = DlVersion.createVersionList(dlItems);

    versions = versions.map(elem => [DlVersion.parseVersion(elem), elem]); 
    versions.sort(function(a, b) {
      let res;
      res = 0;
      for (let i = 0; i < Math.min(a[0].length, b[0].length); i++) {
        if (typeof a[0][i] === 'number'
          && typeof b[0][i] === 'number') {
          res = a[0][i] - b[0][i];
        } else {
          res = a[0][i].toString().localeCompare(b[0][i]);
        }
        if (res != 0) {
          break;
        }
      }
      if (res == 0) {
        res = a[0].length - b[0].length;
      }
      return res;
    });
    return versions[versions.length - 1][1]; 
  }
}

module.exports = DlVersion;
/* vi: se ts=2 sw=2 et: */

