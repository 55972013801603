
/**
 * manage download items
 */
class Items {

  /**
   * read down load items matched version and lang from downloads data.
   */
  static readItems(dlItems, version, lang) {
    const result = [];

    for (let product in dlItems) {

      dlItems[product][version]['modules'][lang].forEach(function(mod) {

        result.push(mod);
      });
    }
    return result;
  }
}

module.exports = Items;

