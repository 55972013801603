const jQuery = require('jquery');
const UrlParam = require('./url-param');
/**
 * manage language setting for site
 */
class Langs {

  static get navigatorLanguage() {
    let result = undefined;
    result = UrlParam.findParam('lang');    
    if (typeof result === 'undefined' || !result) {
      result = window.navigator.language;
    }
    return result;
  }
}


module.exports = Langs;
// vi: se ts=2 sw=2 et:
