
const jQuery = require('jquery');
const popper = require('popper.js');
const bootstrap = require('bootstrap');
const DlVersion = require('./version');
const DlLang = require('./lang');
const DlItems = require('./items');
const Langs = require('./langs');
const StrMap = require('./str-map');

/**
 * manage list1 view user interface
 */
class List1 {
  
  /**
   * get version from ui control
   */
  get versionUI() {
    const verCtrl = jQuery(this.setting.versionQuery);
    const selectedItem = verCtrl.find(':selected');
    let result = selectedItem.val();
    if (result == 'latest') {
      result = DlVersion.getLatestVersion(this.setting.dlItems); 
    }
    return result;
  }

  /**
   * get langualge from ui control.
   */
  get langUI() {
    const langCtrl = jQuery(this.setting.langSelectorQuery);
    const selectedItem = langCtrl.find(':selected');
    return selectedItem.val();
  }


  /**
   * constructor
   */
  constructor(setting) {
    this.setting = setting;
  }

  /**
   * bind html node
   */
  bind() {
    this.attachVersion(this.setting);
    this.attachLang(this.setting);
    this.postSyncUiWithVersion(this.setting);
  }

  /**
   * synchronize ui with version lately.
   */
  postSyncUiWithVersion(setting) {
    setTimeout(function() {
      this.syncUiWithVersion(setting);
    }.bind(this), 100);
  }

  /**
   * set language into UI component
   */
  setLangUI(value, doSync = false) {
    if (this.langUI != value) {
      const langCtrl = jQuery(this.setting.langSelectorQuery);

      let newSelection = undefined;
      langCtrl.children().each(function(idex, item)  {
        let ret = true;
        const itemObj = jQuery(item);
        if (itemObj.val() == value) {
          newSelection = itemObj;
          ret = false;
        }
        return ret;
      });
      if (typeof newSelection === 'undefined') {
        let langAndRegion = value.split('-');   
        if (langAndRegion.length > 1) {
          this.setLangUI(langAndRegion[0], doSync);
        }
      } else {
        newSelection.attr('selected', true);
        if (doSync) {
          this.postSyncDownLoadItemsWithVersionAndLanguage();
        }
      }
    }
  }



  /**
   * synchronize ui with version
   */
  syncUiWithVersion(setting) {
      
    const tmp = jQuery(setting.tmplLangItemQuery); 
 
    const selector = jQuery(setting.langSelectorQuery);
    
    const lastLangSelection = this.langUI;
    selector.empty();
    const version = this.getVersionFromUi(setting);

    let langs = this.getLangs(setting, version); 
    langs.forEach( lang => {
      const item = jQuery(tmp.html());
      item.val(lang);
      item.html(StrMap.replace(uiStrMap['langTag'], lang));
      selector.append(item[0]);
    });
    this.setLangUI(Langs.navigatorLanguage);

    this.postSyncDownLoadItemsWithVersionAndLanguage();
  }


  /**
   * synchronize download items with version and language ui.
   */
  postSyncDownLoadItemsWithVersionAndLanguage() {
    setTimeout(function() {
      this.syncDownLoadItemsWithVersionAndLanguage();
    }.bind(this), 100);
  }
  

  /**
   * synchronize download items with version and language ui.
   */
  syncDownLoadItemsWithVersionAndLanguage() {
    const lang = this.langUI;
    const version = this.versionUI;
    if (lang && version) {
      const dlItems = this.setting.dlItems;
      const dlLinkItem = this.setting.dlLinkItem;
      const itemsRows = DlItems.readItems(dlItems, version, lang);
      const dlTable = jQuery(this.setting.dlItemTableQuery); 
      const tmplItemRow = jQuery(this.setting.tmplDlItemRowQuery);
      const tmplItemCell = jQuery(this.setting.tmplDlItemCellQuery);
      const tmplItemDlLink = jQuery(this.setting.tmplDlItemDlLinkQuery);
      const cellKeys = this.setting.dlItemsCols;

      dlTable.empty();
      itemsRows.forEach(function (itemsRow) {
        const itemRowNode = jQuery(tmplItemRow.html());
        cellKeys.forEach(function(cellKey) {
          const itemNode = jQuery(tmplItemCell.html());
          let item = itemsRow[cellKey]
          if (Array.isArray(item)) {
            item = item.join('');
          }
          if (cellKey in dlLinkItem) {
            const dlNode = jQuery(tmplItemDlLink.html());
            dlNode.text(item) 
            dlNode.attr("href", itemsRow['path']);
            itemNode.append(dlNode);
          } else {
            itemNode.text(item);
          }
          itemRowNode.append(itemNode);
        });
        let item = itemsRow['path'];
        if (Array.isArray(item)) {
          item = item.join('');
        }
 
        itemRowNode.data('path', item);
        dlTable.append(itemRowNode);
      }.bind(this));
    }
  }



  /**
   * attach version ui
   */
  attachVersion(setting) {
    const verCtrl = jQuery(setting.versionQuery);
  
    const hdlr = function(evt) {
      this.postSyncUiWithVersion(setting);
    }.bind(this);
    verCtrl.on('change', hdlr);
  }

  /**
   * attach lang ui
   */
  attachLang(setting) {
    const langCtrl = jQuery(setting.langSelectorQuery);
  
    const hdlr = function(evt) {
      this.postSyncDownLoadItemsWithVersionAndLanguage();
    }.bind(this);
    langCtrl.on('change', hdlr);
  }



  /**
   * get languages
   */
  getLangs(setting, version) {
    return DlLang.getLanguages(setting.dlItems, version);
  }

  /**
   * get version from ui. keyword will be converted real version. 
   */
  getVersionFromUi(setting) {
    let version = this.getVersionFromUiRaw(setting);
    
    if (version == 'latest') {
      version = DlVersion.getLatestVersion(setting.dlItems); 
    }
    return version;
  }


  /**
   * get version from ui. keyword is not converted.
   */
  getVersionFromUiRaw(setting) {
    const verCtrl = jQuery(setting.versionQuery);
     
    const selection = jQuery(':selected', setting.versionQuery);
    let result = undefined;
    if (selection.length) {
       result = selection.eq(0).val(); 
    }
    return result;
  }

}

jQuery(function() {
  const setting = {
    versionQuery: '#version',
    langSelectorQuery: '#lang',
    dlItemTableQuery: '#dl-items',
    tmplLangItemQuery: '#tmpl-lang-item',
    tmplDlItemRowQuery: '#tmpl-dl-item-row',
    tmplDlItemCellQuery: '#tmpl-dl-item-cell',
    tmplDlItemDlLinkQuery: '#tmpl-dl-item-download-link',
    dlItems: dlItems,
    dlItemsCols: ['title', 'description'],
    dlLinkItem: { 'title': true }
  };
  const list = new List1(setting);
  list.bind();
});

// vi: se ts=2 sw=2 et:
